import Messages from "./messages";
import { ErrorContactUs, ErrorContactUsV2 } from "./error-inteface";
import { CommentCard } from "../services/commentCard/types";
import { contactUsv2 } from "../pages/contact-us-v2/data";

const emailRegex =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Validations = {
  validateEmail: (email: string) => {
    let errors;
    if (!email) {
      errors = Messages.ERROR.ContactUs.emailAddress;
    } else if (!emailRegex.test(email)) {
      errors = Messages.ERROR.ContactUs.valid_email;
    }
    return errors;
  },
  validateContactUs: (formValue: CommentCard) => {
    const errors: ErrorContactUs = {};
    if (!formValue?.locationId) {
      errors.locationId = Messages.ERROR.ContactUs.locationId;
    }
    if (!formValue?.tags) {
      errors.tags = Messages.ERROR.ContactUs.tags;
    }
    if (!formValue?.guestName) {
      errors.guestName = Messages.ERROR.ContactUs.guestName;
    }
    if (!formValue?.emailAddress) {
      errors.emailAddress = Messages.ERROR.ContactUs.emailAddress;
    } else if (!emailRegex.test(formValue.emailAddress)) {
      errors.emailAddress = Messages.ERROR.ContactUs.valid_email;
    }
    if (!formValue?.content) {
      errors.content = Messages.ERROR.ContactUs.content;
    }
    if (!formValue?.visitDateTime) {
      errors.visitDateTime = Messages.ERROR.ContactUs.visitDateTime;
    }
    if (!formValue?.qualityRating) {
      errors.qualityRating = Messages.ERROR.ContactUs.qualityRating;
    }
    if (!formValue?.serviceRating) {
      errors.serviceRating = Messages.ERROR.ContactUs.serviceRating;
    }
    if (!formValue?.cleanlinessRating) {
      errors.cleanlinessRating = Messages.ERROR.ContactUs.cleanlinessRating;
    }
    if (!formValue?.orderAccuracyRating) {
      errors.orderAccuracyRating = Messages.ERROR.ContactUs.orderAccuracyRating;
    }
    if (!formValue?.speedRating) {
      errors.speedRating = Messages.ERROR.ContactUs.speedRating;
    }
    if (!formValue?.valueRating) {
      errors.valueRating = Messages.ERROR.ContactUs.valueRating;
    }
    if (!formValue?.overallRating) {
      errors.overallRating = Messages.ERROR.ContactUs.overallRating;
    }
    return errors;
  },
  validateContactUSV2: (formValue: contactUsv2) => {
    const errors: ErrorContactUsV2 = {};

    if (!formValue?.content) {
      errors.content = Messages.ERROR.ContactUsV2.content;
    }
    if (!formValue?.firstName) {
      errors.firstName = Messages.ERROR.ContactUsV2.firstName;
    }
    if (!formValue?.lastName) {
      errors.lastName = Messages.ERROR.ContactUsV2.lastName;
    }
    if (!formValue?.emailAddress) {
      errors.emailAddress = Messages.ERROR.ContactUsV2.emailAddress;
    } else if (!emailRegex.test(formValue.emailAddress)) {
      errors.emailAddress = Messages.ERROR.ContactUsV2.valid_email;
    }
    if (!formValue?.confirmEmailAddress) {
      errors.confirmEmailAddress =
        Messages.ERROR.ContactUsV2.confirmEmailAddress;
    }
    if (formValue.emailAddress !== formValue.confirmEmailAddress) {
      errors.confirmEmailAddress =
        Messages.ERROR.ContactUsV2.validateConfirmEmailAddress;
    }
    if (!formValue?.guestPhone) {
      errors.guestPhone = Messages.ERROR.ContactUsV2.guestPhone;
    }
    if (!formValue?.restaurantState) {
      errors.restaurantState = Messages.ERROR.ContactUsV2.restaurantState;
    }
    if (!formValue?.restaurantCity) {
      errors.restaurantCity = Messages.ERROR.ContactUsV2.restaurantCity;
    }
    if (!formValue?.orderVisitType) {
      errors.orderVisitType = Messages.ERROR.ContactUsV2.orderVisitType;
    }
    if (!formValue?.visitedType) {
      errors.visitedType = Messages.ERROR.ContactUsV2.visitedType;
    }
    if (!formValue?.orderPlaced) {
      errors.orderPlaced = Messages.ERROR.ContactUsV2.orderPlaced;
    }
    if (!formValue?.visitDateTime) {
      errors.visitDateTime = Messages.ERROR.ContactUsV2.visitDateTime;
    }
    return errors;
  },
};

export default Validations;
