import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { AsyncResponseState, AsyncStateType } from "../../store/shares/utility";
import { RootState } from "../../store";
import { FranchiseLocationResponse } from "./types";
import { Config } from "../../config";

// Define a type for the slice state
export interface AllLocationsState
  extends AsyncResponseState<AxiosResponse<FranchiseLocationResponse>> {}

// Define the initial state using that type
const initialState: AllLocationsState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadAllLocations = createAsyncThunk(
  "allLocations/loadAll",
  async (args, thunkAPI): Promise<AxiosResponse<FranchiseLocationResponse>> => {
    const response = await axios.get<FranchiseLocationResponse>(
      `${Config.api}/api/locations`
    );
    return response;
  }
);

export const locationStoreSlice = createSlice({
  name: "allLocations",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadAllLocations
      .addCase(loadAllLocations.pending, (state): AllLocationsState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(
        loadAllLocations.fulfilled,
        (state, action): AllLocationsState => {
          return {
            ...state,
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          };
        }
      )
      .addCase(
        loadAllLocations.rejected,
        (state, action): AllLocationsState => {
          return {
            ...state,
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          };
        }
      );
  },
});

// export the combined reducers
export const AllLocationsReducer = locationStoreSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectAllLocations = (state: RootState) =>
  state.allLocations;
