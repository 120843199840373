import axios from "axios";
import moment, { Moment } from "moment";

export interface contactUsv2 {
  id?: string;
  content: string;
  locationId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  confirmEmailAddress: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  guestPhone: string;
  restaurantState: string;
  restaurantCity: string;
  restaurantLocation: string;
  orderVisitType: string;
  visitedType: string;
  orderPlaced: string;
  partyCount?: string;
  serverName?: string;
  checkNo?: string;
  visitDateTime: string;
  storeId?: number | null;
}

export type ContactUsResponse = contactUsv2[];

// removeOffset is a function that removes the offset from the date
// so the date-time is in Local Time
function removeOffset(date: Moment) {
  const offsetInMinutes = date.utcOffset();
  return date.utc().add(offsetInMinutes, 'm');
}

export const postContactForm = async (api: string, postContactUsData: contactUsv2 ) => {
  const visitDateTimeString =
    postContactUsData.visitDateTime || moment().toISOString();
    
  const visitDateTime = removeOffset(moment(visitDateTimeString)).toISOString(false);
  postContactUsData.visitDateTime = visitDateTime;
  return axios.post<contactUsv2>(`${api}/api/contact-form`, postContactUsData);
}

