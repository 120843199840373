import { makeStyles } from "@mui/styles";

export const SelectControlPageStyle = makeStyles({
  calYearSelect: {
    minWidth: "300px",
    marginLeft: "8px",
    "& .MuiSvgIcon": {
      color: "#fff",
    },
  },
  commonColor: {
    color: "#ed413c",
  },
  calYearInputLbl: {
    color: "#ed413c",
    border: "none",
  },
});
