import { ChangeEvent } from "react";
import { FormControl, FormHelperText, TextareaAutosize } from "@mui/material";

interface Props {
  name: string;
  value?: string;
  placeholder?: string;
  onChange?: (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  minRows?: number;
  maxRows?: number;
  className?: string;
  errors?: string;
  disabled?: boolean;
}
export const TextArea = ({
  name,
  value,
  placeholder,
  onChange,
  minRows,
  maxRows,
  className,
  errors,
  disabled,
}: Props) => {
  return (
    <FormControl
      error={value ? false : errors ? true : false}
      sx={{ width: "100%" }}
    >
      <TextareaAutosize
        name={name}
        defaultValue={value}
        placeholder={placeholder}
        onChange={onChange}
        minRows={minRows}
        maxRows={maxRows}
        className={className}
        disabled={disabled}
      />
      {errors && <FormHelperText>{value ? "" : errors}</FormHelperText>}
    </FormControl>
  );
};
