import { Box, Typography, TypographyTypeMap } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import React from "react";

interface LoadingProps extends DefaultComponentProps<TypographyTypeMap<{}, "span">> {
  message?: string;
}

const Loading: React.FC<LoadingProps> = ({ message = "Loading...", ...rest }) => (
  <Box className="loading">
    <Typography {...rest}>
      <span className="loading-message">{message}</span>
    </Typography>
  </Box>
);

export default Loading;
