import { createServer, Model } from "miragejs";
import { DeepPartial } from "redux";
import { CommentCardsFactory } from "../services/commentCard/factory";
import {
  CommentCard,
  CommentCardsResponse,
} from "../services/commentCard/types";
import { contactUsv2 } from "../pages/contact-us-v2/data";
import { FileUploadsFactory } from "../services/fileUpload/factory";
import { FileUploadResponse } from "../services/fileUpload/types";
import { LocationStoreFactory } from "../services/locationStore/factory";
import {
  FranchiseLocationResponse,
  FranchiseLocation,
} from "../services/locationStore/types";
import locations from "./locations.json";

export function makeServer({ environment = "test" }) {
  return createServer({
    environment,
    factories: {
      location: LocationStoreFactory(),
      comment: CommentCardsFactory(),
      upload: FileUploadsFactory(),
    },

    models: {
      location: Model.extend<Partial<FranchiseLocation>>({}),
      comment: Model.extend<Partial<CommentCard>>({}),
      contactUS: Model.extend<Partial<contactUsv2>>({}),
      upload: Model.extend<Partial<File>>({}),
    },

    routes() {
      this.namespace = "api/";
      this.passthrough("https://public.fuzzystacoshop.com/***");
      // this.passthrough("https://public-api.fuzzystacoshop.com/***");

      this.get("locations", (schema): DeepPartial<FranchiseLocationResponse> => {
        return locations;
      });

      this.get(
        "comments",
        (schema, request): DeepPartial<CommentCardsResponse> => {
          const models = schema.all("comment").models;
          return [...models];
        }
      );

      this.post("comments", (schema, request): DeepPartial<CommentCard> => {
        let data = request?.queryParams;
        const models = schema.create("comment", data);
        return { ...models?.attrs };
      });

      this.post("contact-form", (schema, request): DeepPartial<contactUsv2> => {
        let data = JSON.parse(request?.requestBody);
        const models = schema.create("contactUS", data);
        return { ...models?.attrs };
      });

      this.post(
        "file/upload",
        (schema, request): DeepPartial<FileUploadResponse> => {
          return `https://fuzzys-public-uploads-prod.s3.us-east-2.amazonaws.com/5a1fb952-cfe2-41b6-9728-1ae7e419dd01`;
        }
      );
    },
    seeds(server) {
      server.createList("location", 10);
      server.createList("comment", 10);
      server.create("upload");
    },
  });
}
