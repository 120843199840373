import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "../../store";
import { AsyncResponseState, AsyncStateType } from "../../store/shares/utility";
import { FileUploadResponse } from "./types";

// Define a type for the slice state
export interface FileUploadsState
  extends AsyncResponseState<AxiosResponse<FileUploadResponse>> {}

// Define the initial state using that type
const initialState: FileUploadsState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadFileUploads = createAsyncThunk(
  "FileUploads/load",
  async (fileUpload: File, thunkAPI) => {
    const formData = new FormData();
    formData.append("file", fileUpload);
    return axios.post<FileUploadResponse>("/api/file/upload", formData);
  }
);

export const FileUploadsSlice = createSlice({
  name: "FileUploads",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadFileUploads.pending, (state): FileUploadsState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadFileUploads.fulfilled, (state, action): FileUploadsState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadFileUploads.rejected, (state, action): FileUploadsState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const FileUploadsReducer = FileUploadsSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectFileUploads = (state: RootState) => state.fileUploadReducer;
