import { makeStyles } from "@mui/styles";

export const ContactPageStyle = makeStyles({
  cardContent: {
    width: "100%",
    maxWidth: "900px",
    textAlign: "start",
    margin: "3rem auto",
  },
  locationSelect: {
    minWidth: "100%",
    "& .MuiSvgIcon-root": {
      color: "#ccc",
    },
  },
  commentField: {
    border: "1px solid #ccc",
    padding: "1px 8px",
    borderRadius: "4px",
    color: "#475259",
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "ProximaNova",
    lineHeight: "30px",
    resize: "vertical",
    transition: "background .4s",
    "&:focus-visible": {
      outline: "none",
    },
  },
  secondaryBox: {
    "& .Mui-selected": {
      color: "#000",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .Mui-focused": {
        color: "#000",
      },
      "& label.Mui-focused,label.MuiFormLabel-filled": {
        top: "0px",
      },
      "& label": {
        top: "-10px",
      },
      "& .MuiOutlinedInput-root": {
        color: "#000",
        "& .Mui-focused": {
          border: "1px solid #bdbdbd",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #bdbdbd",
          "&:hover": {
            border: "1px solid #bdbdbd",
          },
        },
      },
    },
  },
  phoneInput: {
    borderRadius: "4px",
    fontSize: "16px",
    border: "1px solid #ccc",
    padding: "2px 10px",
    height: "35px",
    "& .PhoneInputInput": {
      border: "none",
      height: "33px",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },
});
