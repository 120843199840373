import { Factory, Model } from "miragejs";
import { CommentCard } from "./types";

export const CommentCardsFactory = () => {
  return Factory.extend<Partial<CommentCard>>({
    get locationId() {
      return "76";
    },
    get tags() {
      return "Cleanliness Issue";
    },
    get receiptId() {
      return "45141731223";
    },
    get guestName() {
      return "HenryMub";
    },
    get guestPhone() {
      return "89038847790";
    },
    get emailAddress() {
      return "hr.m.hansen@forum.dk";
    },
    get content() {
      return "Make thousands of bucks. Pay nothing.";
    },
    get uploadImageUrl() {
      return "/static/images/bottlecap-teal.png";
    },
    get qualityRating() {
      return 4;
    },
    get serviceRating() {
      return 3;
    },
    get cleanlinessRating() {
      return 2;
    },
    get orderAccuracyRating() {
      return 4;
    },
    get speedRating() {
      return 1;
    },
    get valueRating() {
      return 2;
    },
    get overallRating() {
      return 4;
    },
    get visitDateTime() {
      return "2022-06-28T05:10:00.000+0000";
    },
  });
};
export const CommentCardsModel = Model.extend<Partial<CommentCard>>({});
