import { Factory, Model } from "miragejs";
import { FranchiseLocation } from "./types";

export const LocationStoreFactory = () => {
  return Factory.extend<Partial<FranchiseLocation>>({
    get locationId() {
      return "76";
    },
    get locationName() {
      return "Abilene, TX";
    },
    get address1() {
      return "3351 Turner Plaza Dr";
    },
    get address2() {
      return "#100";
    },
    get city() {
      return "Abilene";
    },
    get county() {
      return "UNITED STATES";
    },
    get state() {
      return "TX";
    },
    get lat() {
      return 75.0;
    },
    get lon() {
      return 123.0;
    },
    get zip() {
      return 79606;
    },
    get storePhone() {
      return "09876543210";
    },
  });
};

export const locationStoreModel = Model.extend<Partial<FranchiseLocation>>({});
