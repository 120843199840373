import { createAction } from "@reduxjs/toolkit";

function withPayloadType<T>() {
  return (t: T) => ({ payload: t });
}

export const createActionWithPayload = <T>(action: string) =>
  createAction(action, withPayloadType<T>());

export { createAction };

export enum AsyncStateType {
  INITIAL,
  PENDING,
  FULFILLED,
  REJECTED,
}
export interface AsyncResponseState<T> {
  currentState: AsyncStateType;
  error?: string;
  response?: T;
}
