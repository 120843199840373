import { ChangeEvent } from "react";
import { TextField } from "@mui/material";

interface Props {
  type?: string;
  name: string;
  label: string;
  value?: string | number;
  onChange?: (v: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  InputLabelProps?: object;
  variant?: "outlined" | "standard" | "filled";
  errors?: string;
  margin?: "dense" | "normal";
  size?: "small" | "medium";
  fullWidth?: boolean;
  required?: boolean;
}
export const InputField = ({
  type,
  label,
  name,
  value,
  onChange,
  variant,
  errors,
  margin,
  size,
  fullWidth,
  required,
}: Props) => {
  return (
    <TextField
      type={type || "text"}
      label={label}
      name={name}
      id={name}
      value={value}
      onChange={onChange}
      InputLabelProps={value ? { shrink: true } : {}}
      variant={variant || "outlined"}
      error={
        name === "email"
          ? errors
            ? true
            : false
          : value
          ? false
          : errors
          ? true
          : false
      }
      helperText={name === "email" ? errors : value ? "" : errors}
      margin={margin || "none"}
      size={size}
      fullWidth={fullWidth || true}
      required={required}
    />
  );
};
