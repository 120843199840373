import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { makeServer } from "./store/mirage";

const environment = process.env.NODE_ENV;
console.log(`using NODE_ENV: ${environment}`);

const disableMirage = process.env.REACT_APP_DISABLE_MIRAGE === "true";
if (!disableMirage) {
  makeServer({ environment });
}

const root = ReactDOM.createRoot(
  document.getElementById("FuzzyPublicMFE") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
