import { FormControl, FormHelperText } from "@mui/material";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

interface Props {
  label: string;
  country: string;
  international?: boolean;
  className?: string;
  value?: string;
  onChange: (v: string) => void;
  errors?: string;
}
export const PhoneInputField = ({
  label,
  className,
  country,
  international,
  value,
  onChange,
  errors,
}: Props) => {
  return (
    <FormControl error={value ? false : errors ? true : false} fullWidth>
      <PhoneInput
        addInternationalOption={international || false}
        international={international || false}
        placeholder={label}
        country={country}
        defaultCountry="US"
        value={value}
        onChange={onChange}
        className={className}
      />
      {errors && <FormHelperText>{value ? "" : errors}</FormHelperText>}
    </FormControl>
  );
};
