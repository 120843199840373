import { MenuItem } from "@mui/material";
import { SelectControl } from "../../../components/selectControl";

interface VisitInformationProps {
  topic: string;
  handleOrderType: (v: string) => void;
  className?: string;
  errors?: string;
}
export const SelectOrderVisit = ({
  topic,
  handleOrderType,
  className,
  errors,
}: VisitInformationProps) => {
  return (
    <>
      <SelectControl
        value={topic}
        onChange={handleOrderType}
        label={"Order Visit Type"}
        className={className}
        errors={errors}
      >
        <MenuItem value={`Dine-In`} key={0}>
          Dine-In
        </MenuItem>
        <MenuItem value={`To Go`} key={1}>
          To-Go
        </MenuItem>
        <MenuItem value={`Delivery`} key={2}>
          Delivery
        </MenuItem>
      </SelectControl>
    </>
  );
};
export const SelectManagerVisit = ({
  topic,
  handleOrderType,
  className,
  errors,
}: VisitInformationProps) => {
  return (
    <>
      <SelectControl
        value={topic}
        onChange={handleOrderType}
        label={"Did a manager visit?"}
        className={className}
        errors={errors}
      >
        <MenuItem value={`Yes`} key={0}>
          Yes
        </MenuItem>
        <MenuItem value={`No`} key={1}>
          No
        </MenuItem>
        <MenuItem value={`Unsure`} key={2}>
          Unsure
        </MenuItem>
      </SelectControl>
    </>
  );
};
export const SelectOrderPlaced = ({
  topic,
  handleOrderType,
  className,
  errors,
}: VisitInformationProps) => {
  return (
    <>
      <SelectControl
        value={topic}
        onChange={handleOrderType}
        label={"How was your order placed?"}
        className={className}
        errors={errors}
      >
        <MenuItem value={`Called-In`} key={0}>
          Called-In
        </MenuItem>
        <MenuItem value={`In person`} key={1}>
          In person
        </MenuItem>
        <MenuItem value={`Placed order online`} key={2}>
          Placed order online
        </MenuItem>
        <MenuItem value={`Mobile app`} key={3}>
          Mobile app
        </MenuItem>
        <MenuItem value={`Delivery`} key={4}>
          Delivery
        </MenuItem>
      </SelectControl>
    </>
  );
};
