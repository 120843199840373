import { Box, CssBaseline } from "@mui/material";
import { Config } from "../config";
import { ContactUsV2 } from "../pages/contact-us-v2";

export const Layout = () => {
  return (
    <>
      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
        <CssBaseline />
        <ContactUsV2 api={Config.api} />
      </Box>
    </>
  );
};
