import { createTheme } from "@mui/material/styles";
const ThemingStyles = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#fa423b",
      },
    },
    typography: {
      fontSize: 12,
      fontFamily: "ProximaNova",
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontFamily: "FoodTruckSignage",
          },
          h2: {
            fontFamily: "FoodTruckSignage",
          },
          h3: {
            fontFamily: "FoodTruckSignage",
          },
          h4: {
            fontFamily: "FoodTruckSignage",
          },
          h5: {
            fontFamily: "FoodTruckSignage",
          },
          h6: {
            fontFamily: "FoodTruckSignage",
          },
          subtitle1: {
            fontFamily: "ProximaNova",
          },
          subtitle2: {
            fontFamily: "ProximaNova",
          },
          body1: {
            fontFamily: "ProximaNova",
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontFamily: "ProximaNova",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: "35px",
          },
          notchedOutline: {
            border: "1px solid #ed413c",
            "&:hover": {
              border: "1px solid #ed413c",
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: "#FFC600",
            color: "#fff",
            zIndex: "998",
          },
        },
      },
      MuiListItem: {},
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "15px",
            padding: "5px 18px 5px 18px",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "4px 14px",
          },
          head: {
            color: "#fff",
            backgroundColor: "#407EC9 !important",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            ":nth-child(odd)": {
              backgroundColor: "rgba(0, 0, 0, 0.07)",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          contained: {
            color: "#fff",
          },
        },
      },
    },
  });

  return theme;
};

export default ThemingStyles;
