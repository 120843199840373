import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { RootState } from "../../store";
import { AsyncResponseState, AsyncStateType } from "../../store/shares/utility";
import { CommentCard, CommentCardsResponse } from "./types";

// Define a type for the slice state
export interface CommentCardsState {
  getAllComment: AsyncResponseState<AxiosResponse<CommentCardsResponse>>;
  postComment: AsyncResponseState<AxiosResponse<CommentCard>>;
}
// Define the initial state using that type
const initialState: CommentCardsState = {
  getAllComment: {
    currentState: AsyncStateType.INITIAL,
  },
  postComment: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadCommentCards = createAsyncThunk(
  "CommentCards/load",
  async (arg, thunkAPI) => {
    return axios.get<CommentCardsResponse>("/api/comments");
  }
);

export const loadPostComment = createAsyncThunk(
  "postComment/load",
  async (postCommentData: CommentCard, thunkAPI) => {
    const visitDateTimeString = postCommentData.visitDateTime || moment().toISOString();
    const visitDateTime = moment(visitDateTimeString).toISOString();
    postCommentData.visitDateTime = visitDateTime;
    return axios.post<CommentCard>(`/api/comments`, postCommentData);
  }
);

export const CommentCardsSlice = createSlice({
  name: "CommentCards",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get comment
      .addCase(loadCommentCards.pending, (state): CommentCardsState => {
        return {
          ...state,
          getAllComment: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(
        loadCommentCards.fulfilled,
        (state, action): CommentCardsState => {
          return {
            ...state,
            getAllComment: {
              currentState: AsyncStateType.FULFILLED,
              response: action.payload,
            },
          };
        }
      )
      .addCase(
        loadCommentCards.rejected,
        (state, action): CommentCardsState => {
          return {
            ...state,
            getAllComment: {
              currentState: AsyncStateType.REJECTED,
              error: `error: ${action.error.code} ${action.error.message}`,
            },
          };
        }
      )
      //post comment
      .addCase(loadPostComment.pending, (state): CommentCardsState => {
        return {
          ...state,
          postComment: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(
        loadPostComment.fulfilled,
        (state, action): CommentCardsState => {
          return {
            ...state,
            postComment: {
              currentState: AsyncStateType.FULFILLED,
              response: action.payload,
            },
          };
        }
      )
      .addCase(loadPostComment.rejected, (state, action): CommentCardsState => {
        return {
          ...state,
          postComment: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const CommentCardsReducer = CommentCardsSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectCommentCards = (state: RootState) =>
  state.commentCardsReducer;
