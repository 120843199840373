import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import PropTypes from "prop-types";
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import * as ReactDOM from "react-dom/client";
import reactToWebComponent from "react-to-webcomponent";
import { InputField } from "../../components/inputFeild";
import Loading from "../../components/loading";
import { PhoneInputField } from "../../components/phoneInputFeild";
import { SelectControl } from "../../components/selectControl";
import { TextArea } from "../../components/textArea";
import { Config } from "../../config";
import useAxios from "../../hooks/useAxios";
import { FranchiseLocation } from "../../services/locationStore/types";
import { AsyncStateType } from "../../store/shares/utility";
import Validations from "../../validations";
import { ErrorContactUsV2 } from "../../validations/error-inteface";
import { ThankYouPage } from "../thank-you";
import { contactUsv2, postContactForm } from "./data";
import { ContactPageStyle } from "./pageStyle";
import {
  SelectManagerVisit,
  SelectOrderPlaced,
  SelectOrderVisit,
} from "./visit";

interface ContactUsV2Props {
  api?: string;
}

export const ContactUsV2 = ({ api = Config.api }: ContactUsV2Props) => {
  const classes = ContactPageStyle();
  const allLocationsState = useAxios<FranchiseLocation[]>(
    `${Config.api}/api/locations`
  );

  const [formValue, setFormValue] = useState<contactUsv2>({
    content: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    confirmEmailAddress: "",
    address1: "",
    address2: "",
    city: "",
    locationId: "",
    zip: "",
    guestPhone: "",
    restaurantState: "",
    restaurantCity: "",
    restaurantLocation: "",
    orderVisitType: "",
    visitedType: "",
    orderPlaced: "",
    partyCount: "",
    serverName: "",
    checkNo: "",
    storeId: null,
    visitDateTime: "",
  });

  const [errors, setErrors] = useState<ErrorContactUsV2>();
  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [locations, setLocations] = useState<FranchiseLocation[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<number | "">("");

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleState = (value: string) => {
    setFormValue({ ...formValue, state: value });
  };

  const handleRestaurantState = (value: string) => {
    setFormValue({ ...formValue, restaurantState: value });
  };

  const handleRestaurantCity = (value: string) => {
    setFormValue({ ...formValue, restaurantCity: value });
  };

  const handleRestaurantLocation = (value: number) => {
    setSelectedLocation(value);
    const selectedLocation = locations[value];
    setFormValue({
      ...formValue,
      restaurantLocation: selectedLocation.locationId || "",
      storeId: selectedLocation.storeId || 0,
    });
  };

  const handleOrderVisit = (value: string) => {
    setFormValue({ ...formValue, orderVisitType: value });
  };

  const handleVisitedType = (value: string) => {
    setFormValue({ ...formValue, visitedType: value });
  };

  const handleOrderPlaced = (value: string) => {
    setFormValue({ ...formValue, orderPlaced: value });
  };

  const handlePhone = (value: string) => {
    setFormValue({ ...formValue, guestPhone: value });
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const error = Validations.validateContactUSV2(formValue);
    setErrors(error);
    if (!Object.keys(error).length) {
      await postContactForm(api, formValue);
      setFormSubmitted(true);
    }
  };

  useEffect(() => {
    if (allLocationsState.currentState !== AsyncStateType.FULFILLED) return;
    allLocationsState.response?.data?.forEach((location) => {
      if (location.state && !states.includes(location.state))
        states.push(location.state);
    });
    setStates(states.sort((a, b) => a.localeCompare(b)));
  }, [allLocationsState, states]);

  useEffect(() => {
    const _cities: string[] = [];
    allLocationsState.response?.data?.forEach((location) => {
      if (
        location.city &&
        !_cities.includes(location.city) &&
        location.state === formValue?.restaurantState
      )
        _cities.push(location.city);
    });
    setCities(_cities);
  }, [formValue?.restaurantState, allLocationsState]);

  useEffect(() => {
    const _locations: FranchiseLocation[] = [];
    allLocationsState.response?.data?.forEach((location) => {
      if (location.city === formValue?.restaurantCity)
        _locations.push(location);
    });
    setLocations(_locations);
    setSelectedLocation("");
  }, [formValue?.restaurantCity, allLocationsState]);

  if (
    allLocationsState.currentState === AsyncStateType.FULFILLED &&
    formSubmitted
  ) {
    return <ThankYouPage />;
  }
  if (allLocationsState.currentState === AsyncStateType.REJECTED) {
    return <div>Something went wrong</div>;
  }
  if (allLocationsState.currentState === AsyncStateType.PENDING) {
    return <Loading variant="h1" />;
  }

  return (
    <div style={{ width: "100%" }}>
      {formValue! && !formSubmitted && (
        <Card className={classes.cardContent} elevation={0}>
          <CardContent>
            <Box
              className={classes.secondaryBox}
              noValidate
              component="form"
              onSubmit={onSubmit}
            >
              <Grid>
                <>
                  <Typography variant="h4">Comments</Typography>
                  <Grid item xs={12}>
                    <Paper elevation={0} sx={{ p: 2 }}>
                      <TextArea
                        name="content"
                        placeholder="Comments *"
                        value={formValue?.content}
                        onChange={handleChange}
                        minRows={2}
                        className={classes.commentField}
                        errors={errors?.content}
                      />
                    </Paper>
                  </Grid>
                </>
                <>
                  <Typography variant="h4">Personal Information </Typography>
                  <Grid container>
                    <>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <InputField
                            name="firstName"
                            label="First Name"
                            value={formValue?.firstName}
                            onChange={handleChange}
                            errors={errors?.firstName}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <InputField
                            name="lastName"
                            label="Last Name"
                            value={formValue?.lastName}
                            onChange={handleChange}
                            errors={errors?.lastName}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <InputField
                            name="emailAddress"
                            label="Email"
                            value={formValue?.emailAddress}
                            onChange={handleChange}
                            errors={errors?.emailAddress}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <InputField
                            name="confirmEmailAddress"
                            label="Confirm Email Address"
                            value={formValue?.confirmEmailAddress}
                            onChange={handleChange}
                            errors={errors?.confirmEmailAddress}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <InputField
                            name="address1"
                            label="Address Line 1"
                            value={formValue?.address1}
                            onChange={handleChange}
                            errors={errors?.address1}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <InputField
                            name="address2"
                            label="Address Line 2"
                            value={formValue?.address2}
                            onChange={handleChange}
                            errors={errors?.address2}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <InputField
                            name="city"
                            label="City"
                            value={formValue?.city}
                            onChange={handleChange}
                            errors={errors?.city}
                          />
                        </Paper>
                      </Grid>
                    </>
                    <>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <SelectControl
                            value={formValue.state || ""}
                            onChange={handleState}
                            label={"State"}
                            className={classes.locationSelect}
                            errors={errors?.locationId}
                          >
                            {states.map((state) => {
                              return (
                                <MenuItem value={state} key={state}>
                                  {state}
                                </MenuItem>
                              );
                            })}
                          </SelectControl>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <InputField
                            name="zip"
                            label="zip"
                            value={formValue?.zip}
                            onChange={handleChange}
                            errors={errors?.zip}
                          />
                        </Paper>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <PhoneInputField
                            label="Phone"
                            country="US"
                            value={formValue?.guestPhone}
                            onChange={handlePhone}
                            errors={errors?.guestPhone}
                            className={classes.phoneInput}
                          />
                        </Paper>
                      </Grid>
                    </>
                  </Grid>
                </>
                <>
                  <Typography variant="h4">Restaurant Information</Typography>
                  <Grid container>
                    <>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <SelectControl
                            value={formValue.restaurantState || ""}
                            onChange={handleRestaurantState}
                            label={"Restaurant State *"}
                            className={classes.locationSelect}
                            errors={errors?.locationId}
                          >
                            {states.map((state) => {
                              return (
                                <MenuItem value={state} key={state}>
                                  {state}
                                </MenuItem>
                              );
                            })}
                          </SelectControl>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <SelectControl
                            value={formValue.restaurantCity || ""}
                            onChange={handleRestaurantCity}
                            label={"Restaurant City *"}
                            className={classes.locationSelect}
                            errors={errors?.locationId}
                          >
                            {cities.map((city) => {
                              return (
                                <MenuItem value={city} key={city}>
                                  {city}
                                </MenuItem>
                              );
                            })}
                          </SelectControl>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <SelectControl
                            value={selectedLocation as number}
                            onChange={handleRestaurantLocation}
                            label={"Restaurant Location *"}
                            className={classes.locationSelect}
                            errors={errors?.locationId}
                          >
                            {locations.map((location, index) => {
                              return (
                                <MenuItem
                                  value={index}
                                  key={location.locationId}
                                >
                                  {location.address1}
                                </MenuItem>
                              );
                            })}
                          </SelectControl>
                        </Paper>
                      </Grid>
                    </>
                  </Grid>
                </>
                <>
                  <Typography variant="h4">Visit Information</Typography>
                  <Grid container>
                    <>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <SelectOrderVisit
                            className={classes.locationSelect}
                            topic={formValue?.orderVisitType}
                            handleOrderType={handleOrderVisit}
                            errors={errors?.orderVisitType}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <SelectManagerVisit
                            className={classes.locationSelect}
                            topic={formValue?.visitedType}
                            handleOrderType={handleVisitedType}
                            errors={errors?.visitedType}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <SelectOrderPlaced
                            className={classes.locationSelect}
                            topic={formValue?.orderPlaced}
                            handleOrderType={handleOrderPlaced}
                            errors={errors?.orderPlaced}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <InputField
                            name="partyCount"
                            label="Party Size"
                            value={formValue?.partyCount}
                            onChange={handleChange}
                            errors={errors?.partyCount}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <InputField
                            name="serverName"
                            label="Server Name"
                            value={formValue?.serverName}
                            onChange={handleChange}
                            errors={errors?.serverName}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <InputField
                            name="checkNo"
                            label="Check#"
                            value={formValue?.checkNo}
                            onChange={handleChange}
                            errors={errors?.checkNo}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              value={formValue?.visitDateTime}
                              onChange={(value) =>
                                setFormValue({
                                  ...formValue,
                                  visitDateTime:
                                    value !== null && value
                                      ? `${moment(value).toISOString()}`
                                      : "",
                                })
                              }
                              renderInput={(params) => (
                                <TextField
                                  name="visitDateTime"
                                  InputLabelProps={
                                    formValue?.visitDateTime
                                      ? { shrink: true }
                                      : {}
                                  }
                                  error={errors?.visitDateTime ? true : false}
                                  helperText={errors?.visitDateTime}
                                  {...params}
                                />
                              )}
                            ></DateTimePicker>
                          </LocalizationProvider>
                        </Paper>
                      </Grid>
                    </>
                  </Grid>
                </>
                <>
                  <Grid item xs={12} textAlign="center">
                    <Paper elevation={0} sx={{ mt: 1 }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Submit
                      </Button>
                    </Paper>
                  </Grid>
                </>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      )}
      {formSubmitted && <ThankYouPage />}
    </div>
  );
};

ContactUsV2.propTypes = {
  api: PropTypes.string.isRequired,
};

const ContactUs = reactToWebComponent(ContactUsV2, React, ReactDOM, {
  shadow: "open",
});
customElements.define("contact-us", ContactUs);
