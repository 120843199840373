const Messages = {
  ERROR: {
    ContactUs: {
      locationId: "Please Select Location",
      tags: "Please Select Topic",
      receiptId: "Receipt number is required",
      guestPhone: "Phone number is required",
      guestName: "Name is required",
      emailAddress: "Email is required",
      valid_email: "Add a valid Email",
      content: "Comment is required",
      visitDateTime: "Date Time is required",
      qualityRating: "Please rating for food Quality",
      serviceRating: "Please rating for overall Service Quality",
      cleanlinessRating: "Please rating for cleanliness",
      orderAccuracyRating: "Please rating for order Accuracy",
      speedRating: "Please rating for speed Service",
      valueRating: "Please rating for value",
      overallRating: "Please rating for overall Experience",
    },
    ContactUsV2: {
      content: "Comment is required",
      locationId: "Location is required",
      firstName: " First name is required",
      lastName: "Last name is required",
      emailAddress: "Email is required",
      valid_email: "Add a valid Email",
      confirmEmailAddress: "Confirmation email is required",
      validateConfirmEmailAddress: "Email address mismatch",
      guestPhone: "Phone number is required",
      restaurantState: "State is required",
      restaurantCity: "City is required",
      orderVisitType: " Visit type is required",
      visitedType: "Visited type is required",
      orderPlaced: "Order placement detail required",
      visitDateTime: "Date and Time is required",
    },
  },
  SUCCESS: {},
};

export default Messages;
