import { configureStore, MiddlewareArray } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { CommentCardsReducer } from "../services/commentCard/slice";
import { FileUploadsReducer } from "../services/fileUpload/slice";
import { AllLocationsReducer } from "../services/locationStore/slice";

const rootReducer = combineReducers({
  allLocations: AllLocationsReducer,
  commentCardsReducer: CommentCardsReducer,
  fileUploadReducer: FileUploadsReducer,
});

const logger = createLogger();

const devMiddleware = process.env.NODE_ENV !== "production" ? [logger] : [];

export const store = configureStore({
  reducer: rootReducer,
  middleware: new MiddlewareArray().concat(...devMiddleware, thunk),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type
export type AppDispatch = typeof store.dispatch;
