import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import { ReactElement } from "react";
import { SelectControlPageStyle } from "./pageStyle";

type OptionValue = string | number | Object | readonly string[];

interface Option<T extends OptionValue> {
  value: T;
  label: string;
}

type Props<T extends OptionValue> = {
  className?: string;
  labelClassName?: string;
  label: string;
  children: ReactElement<Option<T>>[] | ReactElement<Option<T>> | undefined;
  value: T;
  onChange: (value: T) => void;
  errors?: string;
};

export function SelectControlItem<T extends OptionValue>(props: Option<T>) {
  return (
    <option
      label={props.label}
      key={String(props.value)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value={props.value as any}
    />
  );
}

export function SelectControl<T extends OptionValue>({
  className,
  labelClassName,
  value = "" as T,
  onChange,
  label,
  children,
  errors,
}: Props<T>) {
  const classes = SelectControlPageStyle();

  return (
    <FormControl
      className={className}
      error={value ? false : errors ? true : false}
    >
      <InputLabel className={labelClassName} id={label}>
        {label}
      </InputLabel>
      <Select
        className={classes.commonColor}
        labelId={label}
        value={value}
        onChange={(e) => {
          onChange(e.target.value as T);
        }}
        label={label}
      >
        {children && children}
      </Select>
      {errors && <FormHelperText>{value ? "" : errors}</FormHelperText>}
    </FormControl>
  );
}
