import { useEffect, useReducer } from "react";
import axios, { AxiosResponse } from "axios";

import { AsyncResponseState, AsyncStateType } from "../store/shares/utility";

type Action<T> =
  | { type: AsyncStateType }
  | { type: AsyncStateType; payload: AxiosResponse<T> }
  | { type: AsyncStateType; payload: string };

function reducer<T>(
  state: AsyncResponseState<AxiosResponse<T>>,
  action: Action<T>
): AsyncResponseState<AxiosResponse<T>> {
  console.log("reducer", action);
  switch (action.type) {
    case AsyncStateType.INITIAL:
      return {
        ...state,
        currentState: AsyncStateType.INITIAL,
        error: undefined,
      };
    case AsyncStateType.PENDING:
      return {
        ...state,
        currentState: AsyncStateType.PENDING,
        error: undefined,
      };
    case AsyncStateType.FULFILLED:
      return {
        ...state,
        currentState: AsyncStateType.FULFILLED,
        response: ((action || {}) as any).payload,
      };
    case AsyncStateType.REJECTED:
      return {
        ...state,
        currentState: AsyncStateType.REJECTED,
        error: ((action || {}) as any).payload,
      };
    default:
      throw new Error(`Unhandled action type: ${(action as any).type}`);
  }
}

function useAxios<T>(url: string) {
  const [state, dispatch] = useReducer<
    React.Reducer<AsyncResponseState<AxiosResponse<T>>, Action<T>>
  >(reducer, {
    currentState: AsyncStateType.INITIAL,
    response: undefined,
    error: undefined,
  });

  useEffect(() => {
    if (state.currentState !== AsyncStateType.INITIAL) {
      return;
    }
    const fetchData = async () => {
        dispatch({ type: AsyncStateType.PENDING });
      try {
        const result = await axios.get<T>(url);
        console.log("fetchData_result", result);
        dispatch({ type: AsyncStateType.FULFILLED, payload: result });
      } catch (error: any) {
        console.log("fetchData_error", error);
        dispatch({ type: AsyncStateType.REJECTED, payload: error?.message });
      }
    };
    fetchData().then(() => {
      console.log("fetchData_done");
    });
  }, [url, state]);

  return state;
}

export default useAxios;
